<template>
  <div class="detail-card">
    <header class="card-header card-header-info">
      <h4 class="second-title">合同金额</h4>
    </header>
    <section class="card-content">
      <el-row :gutter="10" type="flex" align="normal">
        <el-col :span="6">
          <div class="item">
            <label class="label">修改类型:</label>
            <p class="text">
              {{ changeInfo.modifyMoneyTypeName }}
            </p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="item">
            <label class="label">本次修改金额:</label>
            <p class="text">
              {{ String(changeInfo.modifyMoneyNum) | millimeterFormat | empty }}
            </p>
          </div>
        </el-col>
      </el-row>
      <div class="qy-body-flex-wrapper">
        <!-- 变更前主体 -->
        <div class="qy-body-item pink">
          <h2 class="qy-body-item-title">变更后</h2>
          <el-row :gutter="10" align="normal" class="special-row">
            <el-col :span="12">
              <div class="item">
                <label class="label">币种:</label>
                <p class="text">
                  {{ changeInfo.currency | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">合同金额(含税)(A):</label>
                <p class="text">
                  {{ changeInfo.amount | millimeterFormat }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">不计成本金额(B):</label>
                <p class="text">
                  {{ changeInfo.costAmount | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">有效签约金额(C=A-B):</label>
                <p class="text">
                  {{ changeInfo.effContractAmount | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">发票类型:</label>
                <p class="text">
                  {{ changeInfo.invoiceTypeName | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">可抵扣税率:</label>
                <p class="text">
                  {{ changeInfo.deductibleTaxRate | formatRate }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">可抵扣税额:</label>
                <p class="text">
                  {{ changeInfo.deductibleTax | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">合同金额(不含抵扣税):</label>
                <p class="text">
                  {{ changeInfo.exContractAmount | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 变更后主体 -->
        <div class="qy-body-item gray">
          <h2 class="qy-body-item-title">变更前</h2>
          <el-row :gutter="10" align="normal" class="special-row">
            <el-col :span="12">
              <div class="item">
                <label class="label">币种:</label>
                <p class="text">
                  {{ oldModify.currency | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">合同金额(含税)(A):</label>
                <p class="text">
                  {{ oldModify.amount | millimeterFormat }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">不计成本金额(B):</label>
                <p class="text">
                  {{ oldModify.costAmount | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">有效签约金额(C=A-B):</label>
                <p class="text">
                  {{ oldModify.effContractAmount | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">发票类型:</label>
                <p class="text">
                  {{ oldModify.invoiceTypeValue | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">可抵扣税率:</label>
                <p class="text">
                  {{ oldModify.deductibleTaxRate | formatRate }}
                </p>
              </div>
            </el-col>
                       <el-col :span="12">
              <div class="item">
                <label class="label">可抵扣税额:</label>
                <p class="text">
                  {{ oldModify.deductibleTax | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">合同金额(不含抵扣税):</label>
                <p class="text">
                  {{ oldModify.exContractAmount | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    changeInfo: {
      default() {
        return {};
      },
    },
    oldModify: {
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="less" scoped>
.detail-card {
  .card-content {
    .el-row {
      margin-top: 12px;
    }

    .file-name {
      overflow: hidden;
      text-overflow: ellipsis;

      .link-name {
        color: #997236;
        margin-left: 12px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.special-row {
  /deep/ .el-col {
    margin-bottom: 10px;
  }
}
</style>