<template>
  <div class="content page-box">
    <section class="content-box">
      <!-- 标题 -->
      <h2 class="change-title">
        {{ changeInfo.modifyName }}
        <span class="info-hint"><i class="icon el-icon-warning-outline"></i>本页面内容为最新协议信息, 点击小i可查看修改前协议信息</span></h2>
      <!-- 合同变更信息 -->
      <div class="detail-card">
        <header class="card-header card-header-info ">
          <h4 class="second-title">合同变更信息</h4>
        </header>
        <section class="card-content">
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">变更类型:</label>
                <p class="text">{{isMoneyChange?'框架类协议金额修改':'信息修改'}}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">合同类型:</label>
                <p class="text">
                  {{ changeInfo.contractTypeValue }}
                  <iconWraning v-if="changeInfo.contractTypeValue !== oldModify.contractTypeValue" :content="oldModify.contractTypeValue" />
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">合同名称:</label>
                <p class="text">
                  {{ changeInfo.contractName }}
                  <iconWraning v-if="changeInfo.contractName !== oldModify.contractName" :content="oldModify.contractName" />
                </p>
              </div>
            </el-col>
               <el-col :span="6">
              <div class="item">
                <label class="label">合同编号:</label>
                <p class="text">
                  {{ changeInfo.contractNo | empty }}
                  <iconWraning v-if="changeInfo.contractNo !== oldModify.contractNo" :content="oldModify.contractNo || '-'" />
                </p>
              </div>
            </el-col>

          </el-row>
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
                      <el-col :span="6">
              <div class="item">
                <label class="label">经办人:</label>
                <p class="text">
                  {{ changeInfo.operatorName }}
                  <iconWraning v-if="changeInfo.operatorName !== oldModify.operatorName" :content="oldModify.operatorName || '-'" />  
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">经办日期:</label>
                <p class="text">
                  {{ changeInfo.contractDateStr }}
                  <iconWraning v-if="changeInfo.contractDateStr !== oldModify.contractDateStr" :content="oldModify.contractDateStr || '-'" />
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">关联项目:</label>
                <p class="text" v-if="changeInfo.projectGoalFlag === 'Y'">
                  {{ changeInfo.projectName | addSerial }}
                  <iconWraning v-if="changeInfo.projectName !== oldModify.projectName" :content="oldModify.projectName || '-'" />
                </p>
                <p
                  class="text"
                  v-else
                >非项目目的</p>
              </div>
            </el-col>
            
            <el-col :span="6">
              <div class="item">
                <label class="label">是否需要付款:</label>
                <p class="text">
                  {{ changeInfo.needPay === "Y"? "需要" : "不需要" }}
                  <iconWraning v-if="changeInfo.needPay !== oldModify.needPay" :content="oldModify.needPay === 'Y' ? '需要' : '不需要'" />
                </p>
              </div>
            </el-col>

            
          </el-row>
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
                      <el-col :span="6">
              <div class="item">
                <label class="label">是否供方合同:</label>
                <p class="text">
                  {{ changeInfo.supplyStoreFlag == "0" ? "否" : "是" }}
                  <iconWraning v-if="changeInfo.supplyStoreFlag !== oldModify.supplyStoreFlag" :content="oldModify.supplyStoreFlag == '0' ? '否' : '是'" />
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">形成方式:</label>
                <p class="text">
                  {{ changeInfo.forTypeValue | empty }}
                  <iconWraning v-if="changeInfo.forTypeValue !== oldModify.forTypeValue" :content="oldModify.forTypeValue || '-'" />
                </p>
              </div>
            </el-col>
            <el-col :span="6"   v-if="changeInfo.kgStage">
              <div class="item">
                <label class="label">所属阶段:</label>
                <p class="text">{{ changeInfo.kgStage | kgStageFormatter }}</p>
              </div>
            </el-col>
            <el-col :span="6"   v-if="changeInfo.isRelationFixPj == 1">
              <div class="item">
                <label class="label">具体合同类型:</label>
                <p class="text">{{ changeInfo.fixContractType | fixContractType }}</p>
              </div>
            </el-col>
         
          </el-row>
        </section>
      </div>
      <!-- 签约主体 -->
      <div class="detail-card">
        <header class="card-header card-header-info">
          <h4 class="second-title">签约主体</h4>
        </header>
        <section class="card-content">
          <div class="qy-body-flex-wrapper">
            <!-- 变更前主体 -->
            <div class="qy-body-item pink">
              <h2 class="qy-body-item-title">
                我方签约主体
                <iconWraning v-if="JSON.stringify(changeInfo.ourCompanyList) !== JSON.stringify(oldModify.ourCompanyList)" :content="oldModify.ourCompanyList | ArrayToStringName" />
              </h2>
              <ul class="qy-body-item-detail">
                <li class="qy-body-item-detail-li">
                  <ul>
                    <li v-if="!(changeInfo.ourCompanyList && changeInfo.ourCompanyList.length)">-</li>
                    <li v-for="(item, index) in changeInfo.ourCompanyList" :key="index">{{item.name}}</li>
                  </ul>
                </li> 
              </ul>
            </div>
            <!-- 变更后主体 -->
            <div class="qy-body-item pink">
              <h2 class="qy-body-item-title">
                对方签约主体
                <iconWraning v-if="JSON.stringify(changeInfo.supplierCompanyList) !== JSON.stringify(oldModify.supplierCompanyList)" :content="oldModify.supplierCompanyList | ArrayToStringName" />
              </h2>
              <ul class="qy-body-item-detail">
                <li class="qy-body-item-detail-li">
                  <ul>
                    <li v-if="!(changeInfo.supplierCompanyList && changeInfo.supplierCompanyList.length)">-</li>
                    <li v-for="(item, index) in changeInfo.supplierCompanyList" :key="index">
                      {{item.name}}
                  <span v-show="item.type === 'T04'" v-supplier>{{item.supplierSourceName || '供方'}}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <!-- 合同金额 -->
    <contractAmount v-if="isMoneyChange" :changeInfo="changeInfo" :oldModify="oldModify"/>
      <!-- 招采信息 -->
      <div
      v-if="!isMoneyChange"
        class="detail-card"
      >
        <header class="card-header card-header-info">
          <h4 class="second-title">招采信息</h4>
        </header>
        <section class="card-content">
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="24">
              <div class="item">
                <label class="label">招标/比价/直委:</label>
                <p class="text">
                  {{ changeInfo.orderMsg | empty }}
                  <iconWraning v-if="changeInfo.orderMsg !== oldModify.orderMsg" :content="oldModify.orderMsg || '-'" />
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
            v-if="forminingfileList.length !== 0 "
          >
            <el-col :span="14">
              <div class="item">
                <label class="label">附件:</label>
                <div class="text">
                  <el-table
                    :show-header="false"
                    :data="forminingfileList"
                  >
                    <el-table-column
                      prop="fileName"
                      min-width="250"
                    >
                      <template slot-scope="scope">
                        <div class="file-name">
                          <i class="el-icon-document"></i>
                          <a
                            @click="preview(scope.row, changeInfo.contractId)"
                            class="link-name"
                          >{{ scope.row.fileName }}</a>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="createDate"
                      min-width="50"
                    >
                      <template slot-scope="scope">
                        <div class="light">
                          <span>{{ scope.row.bizCreateDateStr }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      min-width="50"
                    >
                      <template slot-scope="scope">
                        <div class="orderDownload">
                          <span @click="download(scope.row)">下载</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 合同金额 -->
      <div
        class="detail-card"
        v-if="!isMoneyChange"
      >
        <header class="card-header card-header-info">
          <h4 class="second-title">
            合同金额
          </h4>
        </header>
        <section class="card-content">
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">发票类型:</label>
                <p class="text">
                  {{ changeInfo.invoiceTypeName | empty }}
                  <iconWraning v-if="changeInfo.invoiceTypeName !== oldModify.invoiceTypeName" :content="oldModify.invoiceTypeName || '-'" />
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">可抵扣税额:</label>
                <p class="text">
                  {{ changeInfo.deductibleTax | millimeterFormat | empty }}
                  <iconWraning v-if="changeInfo.deductibleTax !== oldModify.deductibleTax" :content="millimeterFormatToString(oldModify.deductibleTax) || '-'" />
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">可抵扣税率:</label>
                <p class="text">
                  {{ changeInfo.deductibleTaxRate | formatRate }}
                  <iconWraning v-if="changeInfo.deductibleTaxRate !== oldModify.deductibleTaxRate" :content="formatRate(oldModify.deductibleTaxRate) || '-'" />
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">合同金额(不含抵扣税):</label>
                <p class="text">
                  {{ changeInfo.exContractAmount | millimeterFormat | empty }}
                  <iconWraning v-if="changeInfo.exContractAmount !== oldModify.exContractAmount" :content="millimeterFormatToString(oldModify.exContractAmount) || '-'" />
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">币种:</label>
                <p class="text">
                  {{ changeInfo.currency | empty }}
                  <iconWraning v-if="changeInfo.currency !== oldModify.currency" :content="oldModify.currency || '-'" />
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">合同金额(含税)(A):</label>
                <p class="text">
                  {{ changeInfo.amount | millimeterFormat }}
                  <iconWraning v-if="changeInfo.amount !== oldModify.amount" :content="millimeterFormatToString(oldModify.amount) || '-'" />
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">不计成本金额(B):</label>
                <p class="text">
                  {{ changeInfo.costAmount | millimeterFormat | empty }}
                  <iconWraning v-if="changeInfo.costAmount !== oldModify.costAmount" :content="millimeterFormatToString(oldModify.costAmount) || '-'" />
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">有效签约金额(C=A-B):</label>
                <p class="text">
                  {{ changeInfo.effContractAmount | millimeterFormat | empty }}
                  <iconWraning v-if="changeInfo.effContractAmount !== oldModify.effContractAmount" :content="millimeterFormatToString(oldModify.effContractAmount) || '-'" />
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row
            v-if="changeInfo.aboveQuotaAmount || changeInfo.aboveQuotaScale"
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">可超额付款比例/金额:</label>
                <p
                  class="text"
                  v-if="changeInfo.aboveQuotaType == 'FIX'"
                >
                  {{ changeInfo.aboveQuotaAmount | millimeterFormat | empty }}
                </p>
                <p
                  class="text"
                  v-else
                >
                  {{ changeInfo.aboveQuotaScale | formatRate }}
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 费项分摊 -->
      <div
        class="detail-card"
        v-if="changeInfo.needPay === 'Y' "
      >
        <header class="card-header card-header-info">
          <h4 class="second-title">费项分摊</h4>
        </header>
        <div class="tab-wrapper" v-if="JSON.stringify(changeInfo.costExpenseList) !== JSON.stringify(oldModify.costExpenseList)">
          <el-row type="flex">
            <el-col :span="6">
              <el-radio-group v-model="radioStatus" size="small" class="radio-custom">
                <el-radio-button v-for="(item, index) in tabList" :key="index" :label="item.labelVal">{{ item.tabName }}</el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
        </div>
        <!-- 补充协议 -->
        <section class="card-content" v-if="radioStatus == '0'">
          <el-row>
            <el-table
              :data="changeInfo.costExpenseList"
              ref="costDate"
              style="width: 100%"
              border
              fit
              :header-row-style="{ height: '36px' }"
            >
              <el-table-column
                v-for="(item, index) in costColumn"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :align="item.align"
              ></el-table-column>
              <el-table-column
                align="right"
                label="分摊金额(含税)"
              >
                <template slot-scope="scope">
                  {{ scope.row.amount | millimeterFormat | empty }}
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </section>
        <!-- 原始协议 -->
        <section class="card-content" v-if="radioStatus == '1'">
          <el-row>
            <el-table
              :data="oldModify.costExpenseList"
              ref="costDate"
              style="width: 100%"
              border
              fit
              :header-row-style="{ height: '36px' }"
              empty-text="原始协议暂无费项分摊数据"
            >
              <el-table-column
                v-for="(item, index) in costColumn"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :align="item.align"
              ></el-table-column>
              <el-table-column
                align="right"
                label="分摊金额(含税)"
              >
                <template slot-scope="scope">
                  {{ scope.row.amount | millimeterFormat | empty }}
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </section>
      </div>
      <!-- 合同付款约定 -->
      <div
        class="detail-card"
        v-if="changeInfo.needPay === 'Y'  &&  !isMoneyChange"
      >
        <header class="card-header card-header-info">
          <h4 class="second-title">合同付款约定</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-col :span="24">
              <div class="item">
                <label class="label">说明信息:</label>
                <p class="text">
                  {{ changeInfo.payAgreement | empty }}
                  <iconWraning v-if="changeInfo.payAgreement !== oldModify.payAgreement" :content="oldModify.payAgreement || '-'" />
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>

      <!-- 合同说明 -->
      <div class="detail-card" v-if="!isMoneyChange">
        <header class="card-header card-header-info">
          <h4 class="second-title">合同说明</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-col :span="24">
              <div class="item">
                <label class="label">说明信息:</label>
                <p class="text">
                  {{ changeInfo.contractNote | empty }}
                  <iconWraning v-if="changeInfo.contractNote !== oldModify.contractNote" :content="oldModify.contractNote || '-'" />
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 信息修改原因 -->
      <div class="detail-card">
        <header class="card-header card-header-info">
          <h4 class="second-title">{{isMoneyChange?'框架协议修改说明':'信息修改原因'}}</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-col :span="24">
              <div class="item">
                <label class="label">说明信息:</label>
                <p class="text">
                  {{ changeInfo.infoChangeCause | empty }}
                  <!-- <iconWraning v-if="changeInfo.infoChangeCause !== oldModify.infoChangeCause" :content="oldModify.infoChangeCause" /> -->
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 附件 -->
      <div class="detail-card fileCard">
        <header class="card-header card-header-info file-header">
          <h4 class="second-title">{{isMoneyChange?'文档':'附件'}}</h4>
          <button
            class="btn btn-lucency"
            @click="downloadAll"
            v-if="changeInfo.contractFiles.length !== 0"
          >
            下载全部附件
          </button>
        </header>
        <section class="card-content">
          <el-row>
            <el-table
              :data="changeInfo.contractFiles"
              ref="fileDate"
              style="width: 100%"
              border
              fit
              :header-row-style="{ height: '36px' }"
            >
              <el-table-column
                label="附件"
                min-width="300"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <i class="el-icon-document"></i>
                  <a
                    @click="preview(scope.row, changeInfo.contractId)"
                    class="linkName"
                  >{{ scope.row.fileName }}</a>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(item, index) in fileColumn"
                :key="index"
                :label="item.label"
                :align="item.align"
                :min-width="item.minWidth"
              >
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top" v-if="scope.row.fileNote && item.prop === 'fileNote' && scope.row.fileNote.length >= 66">
                  <p>{{ scope.row[item.prop] }}</p>
                  <div slot="reference" class="name-wrapper">
                    {{ scope.row[item.prop].slice(0, 66) }}{{ '...' }}
                  </div>
                </el-popover>
                <span v-else>{{ scope.row[item.prop] }}</span>
              </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="left"
                min-width="60"
              >
                <template slot-scope="scope">
                  <div class="fileDownload">
                    <span @click="download(scope.row)"> 下载 </span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </section>
      </div>
    </section>
    <ImageViewer @closeViewer="closeViewer" v-if="showImageViewer" :urls="imageUrl"></ImageViewer>
  </div>
</template>
<script>
import axios from "axios";
import baseurl from "../api/baseUrl.js";
import preview from './mixins/preview'
import iconWraning from '../components/WarningIcon/index.vue'
import contractAmount from './component/contract_amount.vue'
import {fixContractType,kgStageFormatter} from '@/utils/Strmap'

export default {
  name: "businessInfo_Change",
  mixins: [preview],
  data() {
    this.oldModify = {}
    return {
      radioStatus: '0',
      tabList: [
        {
          tabName: '信息修改',
          labelVal: '0'
        },
        {
          tabName: '原始协议',
          labelVal: '1'
        }
      ],
      changeInfo: {
        contractFiles:[]
      },
      // 招采附件
      forminingfileList:[],
      // 表头列表
      costColumn: [
        {
          label: "责任人",
          prop: "operatorName",
        },
        {
          label: "费项",
          prop: "costItemsName",
        },
        {
          label: "预算分摊部门",
          prop: "costItemsDeptName",
        },
      ],
      // 附件列表
      fileDate: [],
      // 附件表头
      fileColumn: [
        {
          label: "备注",
          prop: "fileNote",
          minWidth: 500,
        },
        {
          label: "上传时间",
          prop: "bizCreateDateStr",
          minWidth: 180,
        },
      ],
      Token: "",
    };
  },
  components: {
    iconWraning,
    contractAmount
  },
  filters: {
    ArrayToStringName(arr) {
      return arr.map((item, index) => {
        return index+1 + '.' + item.name + ' '
      }).toString()
    },
    fixContractType,
    kgStageFormatter
  },
  computed:{
    isMoneyChange() {
      return this.changeInfo.type == 'MONEY'
    }
  },
  methods: {
    formatRate(value) {
  if (value === '' || value === null) {
    return '-'
  }
  return (Number(value) * 100).toFixed(2) + "%"
},
    millimeterFormatToString(str) {
      return Number(str).toFixed(2).toString()
    },
    // 根据路由获取参数
    getUrl() {
      return new Promise((resolve) => {
        let params = this.$route.params;
        resolve(params);
      });
    },
    // 获取详细信息
    getChangeInfo(argument) {
      let baseUrl = baseurl + "/cms/inner";
      let instanceId = argument.id;
      let Token = argument.Token;
      this.Token = Token;
      let params = {
        instanceId,
      };
      axios
        .post(
          baseUrl + "/contractModify/getModifyInfo",
          { params },
          {
            headers: {
              "Content-Type": "application/json",
              "Token": Token
            },
          }
        )
        .then((res) => {
          if (res.status !== 200) return;
          if (res.data.code !== "200") {
            if (res.data.code == "303" || res.data.code == "500") {
              this.$message({
                type: "error",
                message: "Token过期，请退出重新登陆",
              });
              return;
            }
            this.$message({
              type: "error",
              message: "找不到该合同信息！",
            });
            return;
          }

          let data = res.data.data

          data.attachFileList.forEach((item, index) => {
            item.status = 'success'
            item.uid = 'a'+ index
          })

          let fileList = data.attachFileList

          this.changeInfo = data
          this.oldModify = data.modifyLastVo
          console.log(this.changeInfo)
          console.log(this.oldModify)

          this.forminingfileList = fileList.filter((item) => {
            return item.fileCategory == "RECRUIT"
          })

          this.changeInfo.contractFiles = fileList.filter((item) => {
            return item.fileCategory == "CONTRACT";
          })
        });
    },
    // 下载附件
    download(row) {
      let baseUrl = baseurl + "/cms/inner";
        let Token = this.Token;
      let data = [{ "fileId": row.docLibId, "contractId": this.changeInfo.contractId }]
      axios
        .post(
          baseUrl + "/file/fileDownload",
          { data },
          {
            headers: {
              "Content-Type": "application/json",
              "Token": Token
            },
          }
        )
        .then((res) => {
          if (res.status !== 200) return;
          if (res.data.code !== "200") {
            if (res.data.code == "303" || res.data.code == "500") {
              this.$message({
                type: "error",
                message: "Token过期，请退出重新登陆",
              });
              return;
            }
            this.$message({
              type: "error",
              message: "请求错误",
            });
            return;
          }
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.style.height = 0;
          iframe.src = res.data.data 
          document.body.appendChild(iframe);
          setTimeout(()=>{
            iframe.remove();
          }, 5 * 60 * 1000);
          })      
    },
    // 下载所有附件
    downloadAll() {
      this.changeInfo.attachFileList.forEach( item => {
        let data = [{ "fileId": item.docLibId, "contractId": this.changeInfo.contractId }]
        let baseUrl = baseurl + "/cms/inner";
        let Token = this.Token;
        axios
          .post(
            baseUrl + "/file/fileDownload",
            { data },
            {
              headers: {
                "Content-Type": "application/json",
                "Token": Token
              },
            }
          )
          .then((res) => {
            if (res.status !== 200) return;
            if (res.data.code !== "200") {
              if (res.data.code == "303" || res.data.code == "500") {
                this.$message({
                  type: "error",
                  message: "Token过期，请退出重新登陆",
                });
                return;
              }
              this.$message({
                type: "error",
                message: "请求错误",
              });
              return;
            }
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.style.height = 0;
            iframe.src = res.data.data
            document.body.appendChild(iframe);
            setTimeout(()=>{
              iframe.remove();
            }, 5 * 60 * 1000);
            }) 
      })
    },
  },
  created() {
    this.getUrl().then((res) => {
      this.getChangeInfo(res);
    });
  },
};
</script>

<style lang="less" scoped>
.page-box {
  .content-box {
    .change-title {
      font-size: 18px;
      font-weight: 600;
      color: #323232;
      padding-top: 24px;
      .info-hint{
        font-size: 12px;
        color: #999;
        float: right;
        margin-top: 3px;
        .icon{
          margin-right: 4px;
        }
      }
    }
    .detail-card {
      .card-content {
        .el-row {
          margin-top: 12px;
        }

        .file-name {
          overflow: hidden;
          text-overflow: ellipsis;

          .link-name {
            color: #997236;
            margin-left: 12px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .orderDownload {
        border-left: 1px solid #e5e5e5;
        span {
          color: #997236;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.linkName{
  margin-left: 10.5px;
}
.tab-wrapper {
  // padding: 10px 0 0 18px;
  padding-top: 10px;
  .radio-custom {
    /deep/ .el-radio-button {
      box-shadow: none !important;
    }
    /deep/ .el-radio-button--small .el-radio-button__inner {
      color: rgb(153, 114, 54);
      border-color: #F0D8A8;
      box-shadow: none;
    }
    /deep/ .is-active {
      .el-radio-button__inner {
        color: rgb(50, 50, 50) !important;
        background-color: #f0d8a8;
      }
    }
  }
}
</style>
<style lang="less">
.el-tooltip__popper{
  max-width: 60% !important;
  width: 300px;
}
.el-popover{
  background: rgba(50,50,50,0.95);
  color: #fff;
  max-width: 95%;
}
.popper__arrow::after{
  border-top-color: rgba(50,50,50,0.95) !important;
}
</style>