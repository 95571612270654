<template>
  <div class="container">
    <el-tooltip class="item" effect="dark" :content="content" placement="top">
      <i class="icon el-icon-warning-outline"></i>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: '-'
    }
  }
}
</script>

<style lang="less" scoped>
  .container{
    display: inline-block;
    .icon{
      cursor: pointer;
      font-size: 12px;
      color: rgb(153, 153, 153);
      margin-left: 6px;
      font-weight: 400;
    }
  } 
</style>
<style lang="less">
.el-tooltip__popper{
  max-width: 60% !important;
  width: auto !important;
}
</style>